import BoardSectionItemHighlight from '@/components/Board/Section/Items/BoardSectionItemHighlight';
import { ImageModule } from '@/components/Image/ImageModule';
import { buildBoardPath } from '@/cutils/builders/buildBoardPath';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { useDefaults } from '@/cutils/hooks/useDefaults';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import { BoardSectionItemBoardHighlightFragment } from './__generated__/BoardSectionItemBoardHighlight.generated';

type Props = {
	boardSectionItemBoardHighlight: BoardSectionItemBoardHighlightFragment;
	sizes: string;
};

const BoardSectionItemBoardHighlight = ({ boardSectionItemBoardHighlight, sizes }: Props) => {
	const {
		constants: { appUrl },
	} = useEnvironment();
	const { fallbackImageModule } = useDefaults();

	const path = buildBoardPath(
		boardSectionItemBoardHighlight.rowId,
		boardSectionItemBoardHighlight.slug,
		boardSectionItemBoardHighlight.path
	);

	const imageComp = (
		<ImageModule image={boardSectionItemBoardHighlight.image || fallbackImageModule} enableZoomEffect={true} sizes={sizes} />
	);

	return (
		<Tracktor
			trackingData={{
				data: {
					target: {
						title: boardSectionItemBoardHighlight.title,
						url: `${appUrl}${path}`,
					},
					type: TrackEventDataType.BoardClick,
				},
			}}
		>
			{({ trackEvent }) => (
				<BoardSectionItemHighlight
					image={imageComp}
					onClick={() => trackEvent({})}
					path={path}
					title={boardSectionItemBoardHighlight.title}
				/>
			)}
		</Tracktor>
	);
};

export default BoardSectionItemBoardHighlight;
