import { format } from 'date-fns';

import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { IconSystem } from '@/icons/IconSystem';
import { TrackEventDataType } from '@/types/tracking';
import { logger } from '@/utils/logging/logger';
import { Tracktor } from 'react-tracktor';

import { Link } from './Link/Link';
import styles from './RadioWidget.module.scss';
import { useRadioWidgetQuery } from './__generated__/RadioWidget.generated';

function formatTime(date: string) {
	return format(date, 'HH:mm');
}

type Props = {
	className?: string;
};

export function RadioWidget({ className = '' }: Props) {
	const environment = useEnvironment();
	const url = `${environment.endpoints.ENDPOINT_RADIO}/br24?autoplay`;

	const { data, loading } = useRadioWidgetQuery({ pollInterval: 60000 });

	if (loading) {
		return null;
	}

	const rootClassName = `${className} ${styles.wrapper}`;
	const broadcastEvent = data?.viewer?.broadcastService?.epg?.[0]?.broadcastEvent;

	if (!data || !broadcastEvent) {
		logger.error(`No data returned from 'RadioWidget'.`);
		return <div className={rootClassName}>Es konnten keine Sendungsinformationen geladen werden.</div>;
	}

	const title = broadcastEvent.publicationOf?.kicker;
	const startTime = formatTime(broadcastEvent.start);
	const endTime = formatTime(broadcastEvent.end);

	return (
		<div className={rootClassName} data-theme-area="dark">
			{/* the additional container is necessary because we are using container queries to layout the component better if enough space is provided */}
			<div className={styles.container}>
				<div className={`body7 ${styles.textLeftContent}`}>
					<div className={styles.text}>BR24 im Radio </div>
					<div className={`heading4 ${styles.radioTitle}`}>{title}</div>
					<div className={styles.text}>
						{startTime} - {endTime} Uhr
					</div>
				</div>

				<div className={styles.right}>
					<IconSystem icon="radio" variant="filled" size="xl" aria-hidden="true" />
					<Tracktor
						trackingData={{
							data: {
								target: {
									title: 'BR24 Radio',
									url,
								},
								type: TrackEventDataType.LinkClick,
							},
						}}
					>
						{({ trackEvent }) => (
							<Link
								className={`${styles.button} gtm-br24-radio body7 no-focus no-hover`}
								onClick={() => trackEvent({})}
								target="_blank"
								href={url}
							>
								<IconSystem icon="play" variant="filled" size="xs" aria-hidden="true" />
								Jetzt hören
							</Link>
						)}
					</Tracktor>
				</div>
			</div>
		</div>
	);
}
