import React from 'react';

import { buildBoardPath } from '@/cutils/builders/buildBoardPath';
import { BoardData } from 'src/pages/[[...board]]';

import ErrorBoundary from '../Error/ErrorBoundary';

import styles from './BoardSection.module.scss';
import BoardSectionError from './Section/BoardSectionError';
import BoardSectionTile from './Section/BoardSectionTile';
import { BoardSectionItemBr24In100SecondsFragment } from './Section/Items/__generated__/BoardSectionItemBR24In100Seconds.generated';
import BoardSectionTitle from './Section/SectionTitle';
import { BoardSectionFragment } from './__generated__/BoardSection.generated';

type Props = {
	boardSection: BoardSectionFragment;
	newsVideo: BoardSectionItemBr24In100SecondsFragment | null | undefined;
	rundschau: NonNullable<BoardData['board']>['rundschau'];
	onClick?: () => void;
	shortnewsItems: BoardData['allShortnews'];
};

export const BoardSection = React.forwardRef<HTMLElement, Props>(({ boardSection, newsVideo, rundschau, shortnewsItems }, ref) => {
	const boardLink = boardSection.boardByBoardLink
		? buildBoardPath(boardSection.boardByBoardLink.rowId, boardSection.boardByBoardLink.slug, boardSection.boardByBoardLink.path)
		: null;
	const visitable = boardSection.boardByBoardLink ? boardSection.boardByBoardLink.visitable : true;
	const hideTitle = boardSection.schema === 'STAGE_CAROUSEL' || boardSection.schema === 'SUBJECTS_NEWS_WEATHER';
	const isHighlightSection = boardSection.schema === 'HIGHLIGHT_3';
	const title = boardSection.title?.trim() || boardSection?.categoryByCategoryId?.title?.trim() || null;
	const showHeroTitleForMobile = boardSection.showHeroTitleForMobile;
	const hasIntroductionText = boardSection.boardByBoardId;

	return (
		<section className={styles.section} ref={ref} data-theme-area={isHighlightSection ? 'dark' : undefined}>
			<BoardSectionTitle
				hideTitle={hideTitle}
				path={boardLink}
				title={title}
				visitable={visitable}
				className={isHighlightSection ? styles.isHighlightWrapper : ''}
			/>

			<ErrorBoundary fallback={<BoardSectionError />}>
				<BoardSectionTile
					boardSection={boardSection}
					isHighlightSection={isHighlightSection}
					newsVideo={newsVideo}
					rundschau={rundschau}
					shortnewsItems={shortnewsItems}
					title={title}
					boardLink={boardLink}
					showHeroTitleForMobile={showHeroTitleForMobile}
					intruductionTextPage={hasIntroductionText}
				/>
			</ErrorBoundary>
		</section>
	);
});
