import { ReactHTML, ReactNode } from 'react';

import { Link } from '@/components/Link/Link';

import styles from './ArticleItemTitleLink.module.scss';

type Props = {
	as?: keyof ReactHTML;
	children: ReactNode;
	path: string;
	title?: string;
	headingClassName?: string;
	className?: string;
};

export function ArticleItemTitleLink({ as = 'h2', children, title, path, headingClassName, className = '' }: Props) {
	const As = as;

	return (
		// On request of Audience Analytics team, we have added static className="aitl-gtm" to TitleLink, Info: Please consult Audience Analytics team before making any change.
		<Link className={`aitl-gtm ${styles.titleLink} ${className} underline-hover`} title={title} href={path}>
			<As className={headingClassName}>{children}</As>
		</Link>
	);
}
