import dynamic from 'next/dynamic';

import { Link } from '@/components/Link/Link';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { IconSystem } from '@/icons/IconSystem';
import { Tracktor } from 'react-tracktor';
import { BoardData } from 'src/pages/[[...board]]';

import { IMAGE_SIZE_ONE_THIRD } from '../../BoardImageSizes';
import BoardSectionItem from '../BoardSectionItem';
import { BoardSectionItemBR24In100Seconds } from '../Items/BoardSectionItemBR24In100Seconds';
import BoardSectionItemBR24Rundschau from '../Items/BoardSectionItemsBR24Rundschau';
import { BoardSectionItemBr24In100SecondsFragment } from '../Items/__generated__/BoardSectionItemBR24In100Seconds.generated';
import BoardSectionTileRow from '../Tile/TileRow';
import BoardSectionTileSection from '../Tile/TileSection';

import styles from './BoardSectionTileRegional4.module.scss';
import { BoardSectionTileRegional4Fragment } from './__generated__/BoardSectionTileRegional4.generated';

const BoardSectionItemMap = dynamic(() =>
	import('@/components/Board/Section/Items/BoardSectionItemMap').then(({ BoardSectionItemMap }) => BoardSectionItemMap)
);

type Props = {
	boardSectionItems: BoardSectionTileRegional4Fragment;
	newsVideo: BoardSectionItemBr24In100SecondsFragment | null | undefined;
	rundschau: NonNullable<BoardData['board']>['rundschau'];
	selectedDistrict: string | null | undefined;
};

const BoardSectionTileRegional4 = ({ boardSectionItems, newsVideo, rundschau, selectedDistrict }: Props) => {
	const environment = useEnvironment();
	const [boardSectionItemTopLeft, boardSectionItemTopCenter, boardSectionItemBottomLeft, boardSectionItemBottomCenter] =
		boardSectionItems.nodes;

	if (!boardSectionItemBottomCenter || !boardSectionItemBottomLeft || !boardSectionItemTopCenter || !boardSectionItemTopLeft) {
		return null;
	}

	return (
		<BoardSectionTileSection>
			<BoardSectionTileRow>
				<Tracktor trackingData={{ data: { inSectionPlacement: 0 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemTopLeft} sizes={IMAGE_SIZE_ONE_THIRD} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 1 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemTopCenter} sizes={IMAGE_SIZE_ONE_THIRD} />
				</Tracktor>
				<div className={styles.videoSection} data-theme-area="dark">
					<div className={styles.videoSectionTitle}>
						<h3 className="heading4">Nachrichten: live und im Überblick</h3>
					</div>
					{!environment.flags.IS_RUNDSCHAU_HIDDEN && (
						<Tracktor trackingData={{ data: { inSectionPlacement: 2 } }}>
							<BoardSectionItemBR24Rundschau rundschau={rundschau} />
						</Tracktor>
					)}
					<Link
						className={`${styles.bannerSection} outline-inside`}
						target="_blank"
						href="https://www.ardmediathek.de/sendung/br24/Y3JpZDovL2JyLmRlL2Jyb2FkY2FzdFNlcmllcy9icm9hZGNhc3RTZXJpZXM6L2JyZGUvZmVybnNlaGVuL2JheWVyaXNjaGVzLWZlcm5zZWhlbi9zZW5kdW5nZW4vcnVuZHNjaGF1"
					>
						<span className={`heading4 ${styles.bannerMediathek}`}>Alle BR24 Nachrichtensendungen in der ARD Mediathek</span>
						<IconSystem className={styles.externalLink} icon="external-link" variant="outline" />
					</Link>
					<Tracktor trackingData={{ data: { inSectionPlacement: 3 } }}>
						<BoardSectionItemBR24In100Seconds newsVideo={newsVideo} />
					</Tracktor>
				</div>
			</BoardSectionTileRow>

			<BoardSectionTileRow>
				<Tracktor trackingData={{ data: { inSectionPlacement: 4 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemBottomLeft} sizes={IMAGE_SIZE_ONE_THIRD} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 5 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemBottomCenter} sizes={IMAGE_SIZE_ONE_THIRD} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 6 } }}>
					<BoardSectionItemMap selectedDistrict={selectedDistrict} />
				</Tracktor>
			</BoardSectionTileRow>
		</BoardSectionTileSection>
	);
};

export default BoardSectionTileRegional4;
