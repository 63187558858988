import ArticleItemBody from '@/components/Article/Items/ArticleItemBody';
import ArticleItemImage from '@/components/Article/Items/ArticleItemImage';
import { ArticleItemPublicationDate } from '@/components/Article/Items/ArticleItemPublicationDate';
import ArticleItemTeaserText from '@/components/Article/Items/ArticleItemTeaserText';
import { ArticleItemTitleLink } from '@/components/Article/Items/ArticleItemTitleLink';
import { ImageModule } from '@/components/Image/ImageModule';
import { useDefaults } from '@/cutils/hooks/useDefaults';
import { IconSystem } from '@/icons/IconSystem';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import styles from './BoardSectionItemTeaser.module.scss';
import { BoardSectionItemTeaserFragment } from './__generated__/BoardSectionItemTeaser.generated';

type Props = {
	boardSectionItemTeaser: BoardSectionItemTeaserFragment;
	isLarge?: boolean;
	sizes: string;
};

const BoardSectionItemTeaser = ({ boardSectionItemTeaser, isLarge, sizes }: Props) => {
	const { fallbackImageModule } = useDefaults();
	const linkTitle = boardSectionItemTeaser?.link?.label;
	const path = boardSectionItemTeaser?.link?.url;
	const publicationDate = boardSectionItemTeaser.updatedAt || boardSectionItemTeaser.createdAt;

	if (!path) {
		return null;
	}

	const imageComp = <ImageModule image={boardSectionItemTeaser.image || fallbackImageModule} enableZoomEffect={true} sizes={sizes} />;

	return (
		<Tracktor
			trackingData={{
				data: {
					target: {
						title: boardSectionItemTeaser.title,
						url: path,
					},
					type: TrackEventDataType.BoardClick,
				},
			}}
		>
			{({ trackEvent }) => (
				<article className={`${styles.wrapper} ${isLarge ? styles.isLargeWrapper : ''}`} onClick={() => trackEvent({})}>
					<ArticleItemImage image={imageComp} path={path} title={boardSectionItemTeaser.title} />

					<div className={styles.content}>
						<header className={styles.header}>
							<section className={styles.meta}>
								<ArticleItemPublicationDate className="body6" publicationDate={publicationDate} />
								<IconSystem icon="external-link" variant="outline" className={styles.teaserIcon} />
							</section>

							<ArticleItemTitleLink
								className={styles.title}
								headingClassName="heading1"
								path={path}
								title={linkTitle || 'Zum weiterführenden Link'}
							>
								{boardSectionItemTeaser.title}
							</ArticleItemTitleLink>
						</header>

						<ArticleItemBody withVerticalPaddingBottom={true}>
							<ArticleItemTeaserText>{boardSectionItemTeaser.description}</ArticleItemTeaserText>
						</ArticleItemBody>
					</div>
				</article>
			)}
		</Tracktor>
	);
};

export default BoardSectionItemTeaser;
