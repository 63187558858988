import { ImageModule } from '@/components/Image/ImageModule';
import { Link } from '@/components/Link/Link';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { lg, xl } from '@/shared/styles/breakpoints';

import styles from './BoardSectionItemHeroNewsteaser.module.scss';

type DataProps = {
	threeNewsData: Record<string, any>;
	title?: string | null;
	boardLink?: string | null;
	showHeroTitleForMobile?: boolean | null;
	isPriority?: boolean;
};

type NewsItem = {
	__typename: string;
	rowId: number;
	path: string;
	slug: string;
	link?: {
		url?: string;
	};
	url: string;
	image: any;
	title: string;
};

const TEASER_SIZES = `(max-width: ${lg}px) 50vw, (max-width: ${xl}px) 12vw, 256px`;

const getBoardLink = (news: NewsItem) => `${news.path},${news.rowId}`;

const getGeneralLink = (news: NewsItem) => {
	if (news.path) {
		return news.path;
	}

	if (news.slug) {
		return `${news.slug},${news.rowId}`;
	}

	if (news.link?.url) {
		return news.link.url;
	}

	return '';
};

const BoardSectionItemHeroNewsteaser = (data: DataProps) => {
	const { images } = useEnvironment();
	const { threeNewsData, title, boardLink, showHeroTitleForMobile, isPriority } = data;

	const newsArray = threeNewsData.filter((x: any) => x !== null);

	let headline;

	if (!boardLink) {
		headline = title ? <h2>{title}</h2> : null;
	} else {
		headline = title ? (
			<Link href={boardLink}>
				<h2>{title}</h2>
			</Link>
		) : null;
	}

	return (
		<div className={styles.newsTeaserDiv}>
			<div
				data-theme="dark"
				className={`${styles.newsTeaserHeadline} ${showHeroTitleForMobile ? styles.showMobileTitle : styles.showDesktopTitle}`}
			>
				{headline}
			</div>

			{newsArray?.map((news: NewsItem, index: number) => (
				<Link className={styles.newsTeaserItem} key={index} href={news.__typename === 'Board' ? getBoardLink(news) : getGeneralLink(news)}>
					<ImageModule
						className={styles.newsTeaserItemImage}
						image={news.image || images.FALLBACK_IMAGE}
						isPriority={isPriority}
						enableZoomEffect={true}
						sizes={TEASER_SIZES}
					/>
					<h3 className={`heading4 ${styles.newsTeaserItemText}`}>{news.title}</h3>
				</Link>
			))}
		</div>
	);
};

export default BoardSectionItemHeroNewsteaser;
