import ArticleItemFeedItem from '@/components/Article/Items/ArticleItemFeedItem';
import { buildBoardPath } from '@/cutils/builders/buildBoardPath';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import { BoardSectionItemFeedItemBoardFragment } from './__generated__/BoardSectionItemFeedItemBoard.generated';

type Props = {
	boardSectionItemBoard: BoardSectionItemFeedItemBoardFragment;
	isLarge?: boolean;
	className?: string;
};

const BoardSectionItemFeedItemBoard = ({ boardSectionItemBoard, className = '' }: Props) => {
	const {
		constants: { appUrl },
	} = useEnvironment();
	const path = buildBoardPath(boardSectionItemBoard.rowId, boardSectionItemBoard.slug, boardSectionItemBoard.path);

	return (
		<Tracktor
			trackingData={{
				data: {
					target: {
						title: boardSectionItemBoard.title,
						url: `${appUrl}${path}`,
					},
					type: TrackEventDataType.BoardClick,
				},
			}}
		>
			{({ trackEvent }) => (
				<ArticleItemFeedItem className={className} onClick={() => trackEvent({})} path={path} title={boardSectionItemBoard.title} />
			)}
		</Tracktor>
	);
};

export default BoardSectionItemFeedItemBoard;
