import { LinkWithChevron } from '@/components/Link/LinkWithChevron';

import styles from './SectionTitle.module.scss';

type Props = {
	hideTitle?: boolean;
	path: string | null;
	title: string | null;
	visitable: boolean;
	className?: string;
};

const BoardSectionTitle = ({ hideTitle, path, title, visitable, className = '' }: Props) => {
	if (!title || hideTitle) {
		return null;
	}

	if (path && visitable) {
		return (
			<header>
				<h3 className={`heading2 ${className} ${styles.wrapper}`}>
					<LinkWithChevron className={styles.title} href={path}>
						{title}
					</LinkWithChevron>
				</h3>
			</header>
		);
	}

	return (
		<header>
			<h3 className={`heading2 ${className} ${styles.wrapper}`}>
				<span className={styles.title}>{title}</span>
			</h3>
		</header>
	);
};

export default BoardSectionTitle;
