import { useEmbedSize } from '@/cutils/hooks/useEmbedSize';

import styles from './BoardSectionItemEmbed.module.scss';
import { BoardSectionItemEmbedFragment } from './__generated__/BoardSectionItemEmbed.generated';

type Props = {
	sectionItemId: number;
	boardSectionItemEmbed: BoardSectionItemEmbedFragment;
	isLarge?: boolean;
};

export const BoardSectionItemEmbed = ({ boardSectionItemEmbed: { url, rowId, altText }, isLarge, sectionItemId }: Props) => {
	const { height, isInitialized, isEmbedInitialized } = useEmbedSize(url, `${rowId}_${sectionItemId}`);

	const urlWithSectionId = new URL(url);
	urlWithSectionId.searchParams.append('viewId', sectionItemId.toString());

	return (
		<article className={`${styles.wrapper} ${isLarge ? styles.large : ''}`}>
			{isInitialized && (
				<iframe
					frameBorder="0"
					className={`${styles.iframe} ${isEmbedInitialized ? styles.initialized : ''}`}
					style={{ height: height ? `${height}px` : undefined }}
					key={rowId}
					src={urlWithSectionId.toString()}
					title={altText || 'Embed'}
					tabIndex={-1}
				/>
			)}
		</article>
	);
};
