import { Link } from '@/components/Link/Link';
import buildAuthorName from '@/cutils/builders/buildAuthorName';
import buildAuthorProfileLink from '@/cutils/builders/buildAuthorProfileLink';

import { BoardSectionItemAuthorFragment } from './__generated__/BoardSectionItemAuthor.generated';

type Props = {
	author: BoardSectionItemAuthorFragment | undefined;
};

const BoardSectionItemAuthor = ({ author }: Props) => {
	const authorName = buildAuthorName(author);
	const authorProfileLink = buildAuthorProfileLink(author);

	if (!authorName) {
		return null;
	}

	if (!authorProfileLink) {
		return <span aria-label="Autor*in">{authorName}</span>;
	}

	return (
		<Link aria-label="Autor*in" title={`Zum Profil von ${authorName}`} href={authorProfileLink}>
			{authorName}
		</Link>
	);
};

export default BoardSectionItemAuthor;
